import { useState, useEffect } from 'react';

import NavigationMenuList from 'atoms/NavigationMenuList';
import Tab from 'atoms/Tab';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { getUserDetails } from 'services/user.service';
import { useSession } from 'next-auth/react';

const NavigationSidebar = ({ className = '', isAdmin = false }) => {
  const [userDetails, setUserDetails] = useState({});
  const route = useRouter();
  const { userId, businessAccountId } = route.query;
  const { data: session } = useSession();

  let isEsIndexPresent = false;

  if (session) {
    isEsIndexPresent = session.user.elasticIndexName ? true : false;
  }

  useEffect(() => {
    async function init() {
      try {
        const response = await getUserDetails(session.user.id);
        setUserDetails(response.entity);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    }
    init();
  }, [session]);

  const menuItems = [
    {
      href: userId
        ? `/?userId=${userId}&businessAccountId=${businessAccountId}`
        : '/',
      icon: 'Store',
      name:
        userDetails?.businessAccount?.industry === 'Manufacturing'
          ? 'Sales Dashboard'
          : 'Store Dashboard',
      active: route.route === '/',
      disabled: false,
      toolTip: true,
      toolTipText: 'Use it to visualize your store current status.',
    },
    // {
    //   href: '/campaigns',
    //   icon: 'Store',
    //   name: 'Campaign',
    //   active: route.route === '/campaigns',
    //   disabled: false,
    //   toolTip: true,
    //   toolTipText: 'Use it to visualize your store current status.',
    // },
    {
      href: userId
        ? `/customers?userId=${userId}&businessAccountId=${businessAccountId}`
        : '/customers',
      icon: 'smile',
      name: 'Customers',
      active: route.route === '/customers',
      disabled: false,
      toolTip: true,
      toolTipText: 'Use it to visualize your store current status.',
    },
    {
      href: userId
        ? `/channels?userId=${userId}&businessAccountId=${businessAccountId}`
        : '/channels',
      icon: 'Folder',
      name: 'Channels',
      active: route.route === '/channels',
      disabled: false,
      toolTip: true,
      toolTipText: 'Use it to visualize your store current status.',
    },
    {
      href: userId
        ? `/Dashboard?userId=${userId}&businessAccountId=${businessAccountId}`
        : '/Dashboard',
      icon: 'Receipt',
      name:
        userDetails?.businessAccount?.industry === 'Manufacturing'
          ? 'Orders'
          : 'Invoices',
      active: route.route === '/Dashboard',
      disabled: !isEsIndexPresent,
      toolTip: true,
      toolTipText: isEsIndexPresent
        ? 'Use it to visualize your store current status.'
        : 'To enable it create a index',
    },
    {
      href: userId
        ? `/Inventory?userId=${userId}&businessAccountId=${businessAccountId}`
        : '/Inventory',
      icon: 'Info-Square',
      name: 'Inventory',
      active: route.route === '/Inventory',
      disabled: !isEsIndexPresent,
      toolTip: true,
      toolTipText: isEsIndexPresent
        ? 'Inventory Management Dashboard'
        : 'To enable it create a index',
    },
    // {
    //   href: userId ? `/?userId=${userId}` : '/',
    //   icon: 'file',
    //   name: 'Invoices',
    //   active: route.route === '/invoice',
    //   disabled: false,
    // },
    {
      href: userId
        ? `/projects?userId=${userId}&businessAccountId=${businessAccountId}`
        : '/projects',
      icon:
        userDetails?.businessAccount?.industry === 'Manufacturing'
          ? 'Store'
          : 'People',
      name:
        userDetails?.businessAccount?.industry === 'Manufacturing'
          ? 'Stores'
          : 'Vendors',
      active:
        route.route.includes('/projects') && route.route.split('/').length < 5,
      disabled: !isEsIndexPresent,
      toolTip: true,
      toolTipText: isEsIndexPresent
        ? 'Explore it'
        : 'To enable it create a index',
    },
    {
      href: userId
        ? `/purchaseOrder?userId=${userId}&businessAccountId=${businessAccountId}`
        : '/purchaseOrder',
      icon: 'plus-b',
      name: 'Create',
      active: route.route.includes('/purchaseOrder'),
      disabled: !isEsIndexPresent,
      toolTip: true,
      toolTipText: isEsIndexPresent
        ? 'Use it to generate your invoices.'
        : 'To enable it create a index',
    },
    {
      href: '/review',
      icon: 'Review',
      name: 'Review',
      active:
        route.route.includes('/projects') && route.route.split('/').length >= 5,
      disabled: !(
        route.route.includes('/projects') && route.route.split('/').length >= 5
      ),
      toolTip: true,
      toolTipText:
        'Currently, No files are under review. Analyze files to review',
    },
    {
      href: '/annotate',
      icon: 'Annotate',
      name: 'Verify',
      active: route.route === '/verify-invoice',
      disabled: route.route !== '/verify-invoice',
      toolTip: true,
      toolTipText:
        'Currently, No files are under annotation. Analyze files to annotate',
    },
    {
      href: '/ruesengine',
      icon: 'Settings',
      name: 'Rules Engine',
      active: route.route === '/ruesengine',
      disabled: true,
    },
    {
      href: '/dataviz',
      icon: 'Dataviz',
      name: 'Data viz',
      active: route.route === '/dataviz',
      disabled: false,
    },
  ];

  return (
    <div
      className={`flex flex-col w-l-[18%] flex-none h-[calc(100vh-50px)] py-6 2xl:h-[calc(100vh-70px)] bg-white border-r border-secondary-200 sticky top-0 ${className}`}
    >
      <div className="px-2.5 flex justify-between flex-col flex-grow">
        {!isAdmin ? (
          <>
            <NavigationMenuList menuItems={menuItems} />
            <div className="absolute bottom-5 w-[calc(100%-34px)]">
              <Tab
                icon="Info-Square"
                listName="Billing"
                href="/billing"
                active={route.route === '/billing'}
              />
              <Tab
                icon="Info-Square"
                listName="API Doc"
                href="/APIDocumentation"
                active={route.route === '/APIDocumentation'}
              />
              <Tab
                icon="Info-Square"
                listName="Help"
                href="/help"
                active={route.route === '/help'}
              />
            </div>
          </>
        ) : (
          <Tab
            icon="Home"
            listName="Customers"
            href="/admin"
            active={route.route === '/'}
          />
        )}
      </div>
    </div>
  );
};

NavigationSidebar.propTypes = {
  isAdmin: PropTypes.bool,
  className: PropTypes.string,
};
export default NavigationSidebar;
