import Text from 'atoms/Text';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
const Tooltip = ({
  tooltipClass = '',
  content = '',
  children,
  padding = 'py-1.5 px-2.5',
  classForArrowIcon = '',
}) => {
  const tooltipPosition = {
    top: 'top',
    bottom: 'bottom',
    left: 'left',
    right: 'right',
    topLeft: 'topLeft',
    topRight: 'topRight',
    bottomLeft: 'bottomLeft',
    bottomRight: 'bottomRight',
  };

  return (
    <div
      className={`bg-black-600 w-fit rounded z-50 ${padding} ${tooltipClass}`}
    >
      <div
        className={`w-3 h-3 transform rotate-45 bg-black-600 ${classForArrowIcon}`}
      ></div>
      <Text variant="bodySmall" textColor="text-white" fontFamily="font-Inter">
        {content}
      </Text>
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  tooltipClass: PropTypes.string,
  content: PropTypes.string,
  position: PropTypes.array,
  padding: PropTypes.string,
};
export default Tooltip;
