import getLayout from '../../Layout/LayoutConfiguration';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { getCustomerDBCredentialsByUserId } from 'services/customer-db-credentials.service';


const updateSessionData = async (session, sessionUpdate) => {
  const responseCredentials = await getCustomerDBCredentialsByUserId();
  if (responseCredentials?.entity) {
    if (session) {
      if (session.user && session.user.isNewUser === false) {
        sessionUpdate({
          isNewUser: session.user.isNewUser,
          name: `${session.user.firstName} ${session.user.lastName}`,
          firstName: session.user.firstName,
          lastName: session.user.lastName,
          profilePic: session.user.profilePic,
          elasticIndexName: responseCredentials?.entity.indexName,
        });
      }
    }
  }
};

const App = ({ Component, pageProps, router }) => {
  if (!['/login', '/welcome'].includes(router.pathname)) {
    const { data: session, update: sessionUpdate } = useSession();
    useEffect(()=>{
      if(session && session.user && session.user.isNewUser === false && !session.user.elasticIndexName){
        updateSessionData(session, sessionUpdate);
      }
    }, [])
  }

  const layout = getLayout(router.pathname);
  return <>{layout({ children: <Component {...pageProps} /> })}</>;
};
export default App;
