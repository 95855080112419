import { createStore } from 'redux';

// Define actions
export const setVerifyJsonData = (data) => ({
  type: 'SET_DATA',
  payload: data,
});
export const setVerifyInvoiceDocAiData = (data) => ({
  type: 'VERIFY_INVOICE_DOCAI_DATA',
  payload: data,
});

// Define reducer
const reducer = (redxState = {}, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return { ...redxState, data: action.payload };
    case 'VERIFY_INVOICE_DOCAI_DATA':
      return { ...redxState, docAiData: action.payload };
    default:
      return redxState;
  }
};

// Create Redux store
export const store = createStore(reducer);
