import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Text = forwardRef(
  (
    {
      variant = 'body',
      fontWeight = 'font-normal',
      textColor = 'text-neutral-900',
      children,
      fontSize,
      className = '',
      fontFamily = '',
      fontStyle = '',
      onClick,
    },
    ref
  ) => {
    const TextType = {
      body: 'body',
      bodySmall: 'bodySmall',
      caption: 'caption',
    };

    const TextStyle = {
      body: 'text-xs xl:text-sm 2xl:text-base',
      bodySmall: 'text-xs 2xl:text-sm', //font poppins
      caption: 'text-[8px] xl:text-xxs 2xl:text-xs',
      xxs: 'text-xxs',
    };

    return (
      <p
        ref={ref}
        variant={TextType[variant]}
        className={`${TextStyle[variant]} ${fontSize} ${fontWeight} ${textColor} ${fontFamily} ${fontStyle} ${className}`}
        onClick={onClick}
      >
        {children}
      </p>
    );
  }
);

Text.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.node,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
  fontStyle: PropTypes.string,
  fontFamily: PropTypes.string,
  onClick: PropTypes.func,
};

export default Text;
