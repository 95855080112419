import axios from 'axios';
import { getAuth } from './identity.service';

export const postWithAuth = (url, entity) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = {
      'content-type': 'application/json',
      'x-access-token': auth.accessToken,
      'x-platform': 'afto-web',
    };
    axios
      .post(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const putWithAuth = (url, entity) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = {
      'content-type': 'application/json',
      'x-access-token': auth.accessToken,
      'x-platform': 'afto-web',
    };
    axios
      .put(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
export const postWithOutAuth = (url, entity) => {
  return new Promise((resolve, reject) => {
    const headers = {
      'content-type': 'application/json',
      'x-platform': 'afto-web',
    };
    axios
      .post(url, entity, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const putWithOutAuth = (url, entity) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        'Content-Type': entity.type,
      },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    };
    axios
      .put(url, entity, config)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.status);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getWithAuth = (url) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = {
      'content-type': 'application/json',
      'x-access-token': auth.accessToken,
      'x-platform': 'afto-web',
    };
    axios
      .get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const getWithOutAuth = (url) => {
  return new Promise((resolve, reject) => {
    const headers = {
      'content-type': 'application/json',
      'x-platform': 'afto-web',
    };
    axios
      .get(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteWithAuth = (url) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = {
      'content-type': 'application/json',
      'x-access-token': auth.accessToken,
      'x-platform': 'afto-web',
    };
    axios
      .delete(url, { headers })
      .then((response) => {
        if (response && response.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

export const deleteMultipleWithAuth = (url, entity) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = {
      'content-type': 'application/json',
      'x-access-token': auth.accessToken,
      'x-platform': 'afto-web',
    };
    axios
      .delete(url, {
        headers,
        data: entity,
      })
      .then((response) => {
        if (response?.data) {
          resolve(response.data);
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
export const fetchStreamPostWithAuth = (url, entity, handleSuccess) => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-access-token': auth.accessToken,
      'x-platform': 'afto-web',
    });

    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(entity),
    })
      .then((response) => {
        if (response.ok) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');
          let buffer = '';

          reader.read().then(function processText({ done, value }) {
            if (done) {
              return resolve();
            }
            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk;
            const parts = buffer.split('\n');
            parts.slice(0, -1).forEach((part) => {
              if (part.trim() !== '') {
                try {
                  const progressData = JSON.parse(part);
                  handleSuccess(progressData);
                } catch (error) {
                  reject(new Error('Failed to parse JSON'));
                }
              }
            });

            buffer = parts[parts.length - 1];
            reader.read().then(processText);
          });
        } else {
          reject(new Error('Failed to fetch service listing'));
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};
