import PropTypes from 'prop-types';
import React from 'react';

const Heading = ({ children, type = 'h1', className = '' }) => {
  const HeadingVariants = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
  };

  const HeadingStyle = {
    h1: 'text-lg xl:text-xl 2xl:text-2xl', //afto
    h2: 'text-sm xl:text-base 2xl:text-xl', //afto
    h3: 'text-xs xl:text-sm 2xl:text-lg', //afto,
    h4: 'text-sm xl:text-base 2xl:text-24px',
    h5: 'text-xl',
  };
  const Type = HeadingVariants[type] ? HeadingVariants[type] : 'h1';
  return (
    <Type className={`${HeadingStyle[type]} ${className}`}>{children}</Type>
  );
};

Heading.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
export default Heading;
