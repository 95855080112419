import Button from 'atoms/Button';
import Heading from 'atoms/Heading';
import Logo from 'atoms/Logo';
import Text from 'atoms/Text';
import Image from 'next/image';
import Anchor from '../atoms/Anchor';
const ErrorBoundary = ({ retry }) => {
  return (
    <div className="min-h-screen flex justify-center py-4 3xl:py-8">
      <div className="text-center flex flex-col justify-between items-center">
        <div className="flex flex-col items-center">
          <Logo
            src="/images/aftoLogoBlue.svg"
            alt="Afto"
            className="w-[90px] h-[50px] 2xl:w-[110px] 2xl:h-[77px]"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="w-[300px] h-[200px] xl:w-[400px] xl:h-[300px] 3xl:w-[506px] 3xl:h-[377px] relative">
            <Image
              src="/images/somethingWrong.svg"
              alt="something went wrong"
              layout="fill"
            />
          </div>
          <div className="flex flex-col justify-center items-center mt-3">
            <Heading
              type="h1"
              className="text-black-800 font-semibold mt-3 2xl:mt-6 mb-1.5"
            >
              Sorry our website has encountered an issue
            </Heading>
            <Text variant="body" textColor="text-secondary-100">
              We are working on improving the Afto experience, We will be back
              up shortly!
            </Text>
            <Button
              btnClass="gap-1.5 mt-3 2xl:mt-6"
              flex="flex"
              icon="Retry"
              showIcon={true}
              size={16}
              varient="largeContainedAlternate"
              onClick={retry}
            >
              Retry
            </Button>
          </div>
        </div>
        <div>
          <div className="flex items-center gap-x-2 justify-center">
            <Image
              src="/images/QuestionMark.svg"
              alt="Question mark"
              width={24}
              height={24}
            />
            <Text variant="body" textColor="text-black-800">
              Need Help?
            </Text>
          </div>
          <div className="flex mt-2.5 2xl:mt-5">
            <Text variant="body" textColor="text-black-800">
              Write to us on
            </Text>
            <Anchor
              href="mailto:support@afto.com"
              className="text-primary-900 font-medium text-xs xl:text-sm 2xl:text-base"
            >
              &nbsp; support@afto.com
            </Anchor>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
