import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';
import IcomoonIcon from './IcomoonIcon';

const Avatar = ({
  className = '',
  shape = 'circle',
  size = 'large',
  imgSrc = '/images/icons/profile-img.svg',
  imgAlt = 'icon',
  onClick = () => {},
  bgColor,
  showBorder,
  active = false,
}) => {
  const avatarShape = {
    normal: 'rounded-none',
    square: 'rounded-lg',
    circle: 'rounded-full',
  };

  const avatarSize = {
    normal: '',
    small: 'w-6 h-6 text-xs',
    medium: 'w-6 h-6 xl:w-[30px] xl:h-[30px] 2xl:w-9 2xl:h-9',
    large:
      'w-[26px] h-[26px] xl:w-[30px] xl:h-[30px] 2xl:w-[42px] 2xl:h-[42px]',
    large2: 'w-14 h-14 2xl:w-[66px] 2xl:h-[66px]',
    extraLarge: 'w-[120px] h-[120px] 2xl:w-[154px] 2xl:h-[154px]',
  };

  return (
    <div
      className={`${avatarShape[shape]} ${
        avatarSize[size]
      } object-contain relative overflow-hidden ${bgColor} ${
        showBorder && 'border-4 border-primary-800'
      } ${className}`}
      onClick={onClick}
    >
      <Image src={imgSrc} layout="fill" alt={imgAlt} />
      {active && (
        <div className="absolute inset-0 w-full h-full bg-primary-900 bg-opacity-50 z-50 flex justify-center items-center">
          <IcomoonIcon
            icon="Check-2"
            className="fill-white h-[18px] w-[22px]"
          />
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  shape: PropTypes.string,
  size: PropTypes.string,
  imgAlt: PropTypes.string,
  onClick: PropTypes.func,
  bgColor: PropTypes.string,
};
export default Avatar;
