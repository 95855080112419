import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAuth = () => cookies.get('AUTH');

export const setAuth = (authObject) => {
  const monthInMillis = 365 * 3 * 86400000;
  cookies.set('AUTH', JSON.stringify(authObject), {
    path: '/',
    expires: new Date(Date.now() + monthInMillis),
  });
  return authObject;
};

export const removeAuth = () => {
  cookies.remove('AUTH', { path: '/' });
};
