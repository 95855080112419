import {
  getWithAuth,
  getWithOutAuth,
  postWithAuth,
  postWithOutAuth,
  putWithAuth,
  putWithOutAuth,
} from 'services/http.service';
import {
  AUTHENTICATE_USER,
  CREATE_USER,
  CREATE_VENDOR_ONBOARDING_DATA,
  GET_USER_DETAILS,
  GET_USER_ONBOARDING_API,
  RESEND_OTP,
  SIGNIN_USER_BY_EMAIL,
  SIGNUP_USER_BY_EMAIL,
  USER_ONBOARDING_API,
  VERIFY_OTP,
  GENERATE_COMPANY_LOGO_URL,
  GET_COMPANY_LOGO_URL,
  UPDATE_COMPANY_LOGO,
  GET_BUSINESS_DETAILS,
  GET_BUSINESS_DETAILS_BY_EMAIL,
  GET_VENDOR_BUSINESS_DETAILS_BY_SUPPLIER_ID,
  CREATE_API_AUTH_TOKEN,
  VERIFY_API_AUTH_TOKEN,
} from './url.service';

export const createUser = (user) => postWithOutAuth(CREATE_USER, user);
export const createVendorOnboardingData = (data) =>
  postWithOutAuth(CREATE_VENDOR_ONBOARDING_DATA, data);
export const authenticateUser = (email) =>
  getWithOutAuth(AUTHENTICATE_USER(email));
export const signUpUserByEmail = (user) =>
  postWithOutAuth(SIGNUP_USER_BY_EMAIL, user);
export const signInUserByEmail = (email) =>
  postWithOutAuth(SIGNIN_USER_BY_EMAIL, email);
export const verifyUserOtp = (data) => postWithOutAuth(VERIFY_OTP, data);
export const resendUserOtp = (email) => postWithOutAuth(RESEND_OTP, email);
export const createUserOnboardingData = (onBoardingData) =>
  postWithAuth(USER_ONBOARDING_API, onBoardingData);
export const updateUserOnboardingData = (onBoardingData) =>
  putWithAuth(USER_ONBOARDING_API, onBoardingData);
export const getUserOnboardingData = (userId) =>
  getWithAuth(GET_USER_ONBOARDING_API(userId));
export const getUserDetails = (userId) => getWithAuth(GET_USER_DETAILS(userId));
export const getBusinessDetails = (businessId) =>
  getWithAuth(GET_BUSINESS_DETAILS(businessId));
export const getBusinessDetailsByEmail = (businessEmail) =>
  getWithAuth(GET_BUSINESS_DETAILS_BY_EMAIL(businessEmail));
export const getVendorBusinessDetailsBySupplierID = (supplierId) =>
  getWithAuth(GET_VENDOR_BUSINESS_DETAILS_BY_SUPPLIER_ID(supplierId));
export const generateCompanyLogoUrl = (email) =>
  getWithAuth(GENERATE_COMPANY_LOGO_URL(email));
export const uploadCompanyLogoUrl = (url, file) => putWithOutAuth(url, file);
export const getCompanyLogoUrl = (fileUrl = 'uploaded') =>
  getWithAuth(GET_COMPANY_LOGO_URL(fileUrl));
export const updateCompanyLogo = (logoDetails) =>
  putWithAuth(UPDATE_COMPANY_LOGO, logoDetails);

export const createAPIAuthToken = () => getWithAuth(CREATE_API_AUTH_TOKEN);
export const verifyAPIAuthToken = (data) =>
  postWithOutAuth(VERIFY_API_AUTH_TOKEN, data);

// check if in use
export const getUsers = () => getWithOutAuth(`${URL}/users`);
export const getUser = (userId) =>
  getWithOutAuth(`http://localhost:3000/users/${userId}`);
