import EmptyLayout from './EmptyLayout';
import Layout from './Layout';

const LayoutConfig = [
  {
    path: '/login',
    layout: EmptyLayout,
  },
  {
    path: '/404',
    layout: EmptyLayout,
  },
  {
    path: '/500',
    layout: EmptyLayout,
  },
  {
    path: '/welcome',
    layout: EmptyLayout,
  },
  {
    path: '/',
    layout: Layout,
  },
];

const getLayout = (path) => {
  const configuration = LayoutConfig.find((config) =>
    path.includes(config.path)
  );
  if (configuration) return configuration.layout;
  return EmptyLayout;
};

export default getLayout;
