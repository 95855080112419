import React from 'react';
import PropTypes from 'prop-types';
import Tab from './Tab';

const NavigationMenuList = ({
  menuItems = [],
  listMainClass,
  listItemClass,
  ...properties
}) => {
  return (
    <ul className={`space-y-2 ${listMainClass}`}>
      {menuItems.map((item, index) => (
        <Tab
          icon={item.icon}
          listItemClass=""
          listName={item.name}
          href={item.href}
          key={index}
          active={item.active}
          disabled={item.disabled}
          toolTip={item.toolTip}
          toolTipText={item.toolTipText}
        />
      ))}
    </ul>
  );
};
NavigationMenuList.propTypes = {
  className: PropTypes.string,
  listMainClass: PropTypes.string,
  listItemClass: PropTypes.string,
  menuItems: PropTypes.array,
};
export default NavigationMenuList;
