import React from 'react';
import IcomoonIcon from './IcomoonIcon';
import Heading from './Heading';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Tooltip from 'molecules/ToolTip';
import Text from 'atoms/Text';

const Tab = ({
  icon,
  listItemClass = '',
  listName,
  href = '',
  active = false,
  disabled = false,
  toolTip,
  toolTipText,
}) => {
  return (
    <Link href={disabled ? {} : href}>
      <li
        className={`px-3 py-1 xl:px-4 xl:py-1.5 ${
          active && 'bg-primary-900 bg-opacity-10'
        } flex items-center group relative rounded gap-x-2 transition-all ease-in-out duration-200 ${
          disabled ? 'cursor-default' : 'cursor-pointer hover:bg-[#1756841a]'
        } ${listItemClass}`}
      >
        <span className="relative flex items-center gap-x-2.5">
          <IcomoonIcon
            icon={icon}
            className={`w-[14px] h-[14px] xl:w-4 xl:h-4 2xl:w-6 2xl:h-6 flex-shrink-0 ${
              disabled
                ? "fill-secondary-100"
                : active
                ? "fill-primary-900"
                : "fill-darkGrey-400  group-hover:fill-primary-900"
            } `}
          />
          <Text
            variant="bodySmall"
            fontWeight={active ? "font-medium" : "font-normal"}
            textColor={
              disabled
                ? "text-secondary-100"
                : active
                ? "text-primary-900"
                : "text-darkGrey-400"
            }
            className={`pt-0.5 ${!disabled && "group-hover:text-primary-900"}`}
          >
            {listName}
          </Text>
          {toolTip && (
            <Tooltip
              content={toolTipText}
              tooltipClass="hidden group-hover:block absolute left-full ml-4 whitespace-nowrap"
              classForArrowIcon="absolute -left-1.5 top-1/2 -translate-y-1/2"
            />
          )}
        </span>
      </li>
    </Link>
  );
};

Tab.propTypes = {
  icon: PropTypes.string,
  listItemClass: PropTypes.string,
  listName: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default Tab;
