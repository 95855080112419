import { SearchContext } from '../context/SearchContext';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import IcomoonIcon from './IcomoonIcon';

const SearchBox = () => {
  const { setSearchText } = useContext(SearchContext);
  const [value, setValue] = useState('');

  const router = useRouter();
  const {
    query: { folderId, projectId, userId },
    pathname,
  } = router;

  const handleClear = () => {
    setValue('');
    setSearchText('');
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchText(value);
    }, 500); // Adjust the debounce delay as needed (e.g., 500ms)

    return () => clearTimeout(delayDebounceFn);
  }, [value, setSearchText]);

  useEffect(() => {
    handleClear();
  }, [pathname, folderId, projectId, userId]);

  return (
    <div className="relative w-[230px] xl:w-[300px] 2xl:w-[393px] h-[25px] xl:h-[36px] flex items-center">
      <span className="absolute left-[3%]">
        <div className="relative w-2.5 h-2.5 xl:w-3 xl:h-3 2xl:w-[17px] 2xl:h-[17px]">
          <Image src="/images/SearchIcon.svg" alt="Search" layout="fill" />
        </div>
      </span>
      <input
        className="w-full h-full rounded-lg focus:outline-none bg-secondary-50 placeholder:text-secondary-100 pl-[9%] pr-8 py-2 text-black-900 font-normal text-xxs xl:text-xs 2xl:text-sm focus:placeholder-transparent"
        placeholder="Search"
        value={value}
        onChange={handleChange}
      />
      {value && (
        <IcomoonIcon
          icon="close2"
          size="10"
          className="cursor-pointer h-3 w-3 2xl:h-4 2xl:w-4 flex-shrink-0 absolute right-3"
          onClick={handleClear}
        />
      )}
    </div>
  );
};

export default SearchBox;
