import React from 'react';
import PropTypes from 'prop-types';
import NextImage from 'next/image';

const Logo = ({ ...property }) => {
  return (
    <div className={`select-none relative ${property.className}`}>
      <NextImage
        onClick={property.onClick}
        src={property.src}
        layout="fill"
        alt={`${property.alt}`}
      />
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  alt: PropTypes.string,
};
export default Logo;
