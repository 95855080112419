import React from 'react';
import PropTypes from 'prop-types';
const Anchor = ({ href, className, children, ...props }) => {
  return (
    <a href={href} className={className} {...props}>
      {children}
    </a>
  );
};

Anchor.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.string,
};
export default Anchor;
