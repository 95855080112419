import App from 'components/App/App';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { SearchProvider } from '../context/SearchContext';
import { SessionProvider } from 'next-auth/react';
import '../styles/globals.css';
import { Provider } from 'react-redux';
import { store } from 'redux-store/store';


export default function AfoApp({
  Component,
  pageProps: { session, ...pageProps },
  router,
}) {
  return (
    <ErrorBoundaryWrapper>
      <SessionProvider session={session}>
        <SearchProvider>
          <Provider store={store}>
            <App Component={Component} pageProps={pageProps} router={router} />
          </Provider>
        </SearchProvider>
      </SessionProvider>
    </ErrorBoundaryWrapper>
  );
}
